




















































import { Component, Vue } from 'vue-property-decorator';
import { ViewAdminCMSPage } from '@/types/cms';
import { DataTableHeader } from 'vuetify/types/';
import { getViewAdminCMSPages } from '@/utils/cms';
import router from '@/router';

/**
 * CMSAdminPages Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue')
    }
})
export default class CMSAdminPages extends Vue {
    // array with cms pages
    private cmsPages: Array<ViewAdminCMSPage> = [];

    /**
     * Function will be executed on component load
     * fetches cms pages
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.cmsPages = getViewAdminCMSPages().filter(view => {
            return view.admin !== undefined;
        });
    }

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader>{
        return [
            {
                text: this.$t('table.headers.name').toString(),
                value: 'view.name'
            },
            {
                text: this.$t('table.headers.actions').toString(),
                value: 'actions',
                sortable: false,
                filterable: false
            }
        ];
    }

    /**
     * Navigates to the edit page
     * 
     * @param page page
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editPage(page: ViewAdminCMSPage) {
        if (!page.admin) return;

        router.push({ name: page.admin.routeName });
    }

    /**
     * Deletes the given page
     * 
     * @param page page
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deletePage(page: ViewAdminCMSPage) {
        if (page.view.isLocalPage) return;

        // TODO remove
        console.log(page);
    }
}
